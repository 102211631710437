import { Star as StarIcon } from '@mui/icons-material';
import {
  Alert,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { yellow } from '@mui/material/colors'; // eslint-disable-line import/extensions
import type { Event, Person } from 'core';
import { isRogaine } from 'core';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import uri from 'uri-tag';
import { CompetitionChip } from '../../components/chips/competition.js';
import { NotPublicChip } from '../../components/chips/not-public.js';
import { NotPublishedChip } from '../../components/chips/not-published.js';
import { ConfirmButton } from '../../components/confirm-button/confirm-button.js';
import { EventSeriesEntity } from '../../components/entity/event-series.js';
import { GroupEntity } from '../../components/entity/group.js';
import { PersonAvatar } from '../../components/entity/person.js';
import { ShareOnFacebook } from '../../components/facebook/share.js';
import {
  InfoFieldNonTextView,
  InfoFieldStack,
  InfoFieldView,
} from '../../components/info-field/info-field.js';
import { CenterMiniMap } from '../../components/map/center-mini-map/controller.js';
import { OverlayImageLayer } from '../../components/map/layers/overlay-image.js';
import { MapStyles } from '../../components/map/map-styles.js';
import { PageTitle } from '../../components/page-title/page-title.js';
import { RelativeTimestamp } from '../../components/relative-timestamp/relative-timestamp.js';
import { useBoolFlag } from '../../hooks/use-flag.js';
import { sortByCourseTitle } from '../../utils/course-sort.js';
import {
  formatDate,
  formatTime,
  formatTimeDifferentDay,
} from '../../utils/format.js';
import { AddOverlay } from './add-overlay/controller.js';
import { EditDetails } from './edit/controller.js';
import { ConnectMatchingTracks } from './matching-tracks.js';
import type { ViewMode } from './types.js';

interface Props {
  data: Event.WithCourses;
  mode: ViewMode;
  onAddOverlayImageClick: () => void;
  onControlsClick: () => void;
  onEditClick: () => void;
  onPublishClick: () => void;
  person: Person.Type;
  redact: boolean;
  redactForOthers: boolean;
  refresh: () => void;
}

export const EventDetailsView: FC<Props> = ({
  data,
  mode,
  onAddOverlayImageClick,
  onControlsClick,
  onEditClick,
  onPublishClick,
  person,
  redact,
  redactForOthers,
  refresh,
}) => (
  <PageTitle title={data.title}>
    <Stack spacing={3}>
      <ConnectMatchingTracks event={data} />

      <Card sx={{ width: '100%' }}>
        <Collapse in={mode === 'view'}>
          <CardContent sx={{ mb: -1 }}>
            <Stack spacing={1}>
              <Box display="flex" flex={1} justifyContent="space-between">
                <Stack direction="column" spacing={0}>
                  {data.eventSeriesId ? (
                    <Typography variant="body1">
                      <EventSeriesEntity
                        eventSeriesId={data.eventSeriesId}
                        link
                      />
                    </Typography>
                  ) : null}
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h3">{data.title}</Typography>
                    {!data.public ? (
                      data.groupIds ? (
                        data.groupIds.map((groupId) => (
                          <GroupEntity
                            key={groupId}
                            groupId={groupId}
                            link={true}
                          />
                        ))
                      ) : (
                        <NotPublicChip />
                      )
                    ) : undefined}
                    {data.competition ? <CompetitionChip /> : undefined}
                    {!data.published ? <NotPublishedChip /> : undefined}
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                  {useBoolFlag('share.facebook') && data.published ? (
                    <ShareOnFacebook
                      url={uri`https://otrax.app/#/event/${data._id}`}
                    />
                  ) : null}
                  {data.starUserIds?.includes(person._id) ? (
                    <StarIcon sx={{ color: yellow[500] }} />
                  ) : undefined}
                </Stack>
              </Box>
              <InfoFieldStack>
                <InfoFieldView label="Date">
                  {formatDate(data.start) ?? '-'}
                </InfoFieldView>
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                <InfoFieldView label="Start From">
                  {formatTime(data.start) ?? '-'}
                </InfoFieldView>
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                {!DateTime.fromJSDate(data.start)
                  .toLocal()
                  .startOf('day')
                  .hasSame(
                    DateTime.fromJSDate(data.finish).toLocal(),
                    'day',
                  ) ? (
                  <InfoFieldView label="Start Util">
                    {formatTimeDifferentDay(data.finish) ?? '-'}
                  </InfoFieldView>
                ) : (
                  <InfoFieldView label="Start Util">
                    {formatTime(data.finish) ?? '-'}
                  </InfoFieldView>
                )}
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                {!DateTime.fromJSDate(data.start)
                  .toLocal()
                  .startOf('day')
                  .hasSame(DateTime.fromJSDate(data.close).toLocal(), 'day') ? (
                  <InfoFieldView label="Course Close">
                    {formatTimeDifferentDay(data.close) ?? '-'}
                  </InfoFieldView>
                ) : (
                  <InfoFieldView label="Course Close">
                    {formatTime(data.close) ?? '-'}
                  </InfoFieldView>
                )}
              </InfoFieldStack>
            </Stack>
          </CardContent>

          {data.overlayImage && redactForOthers ? (
            <CardContent>
              <Alert severity="info">
                Other users will not be able to see the map overlay until after
                the "Course Close" time above.
              </Alert>
            </CardContent>
          ) : null}

          <Box mt={3}>
            <CenterMiniMap
              height={200}
              center={data.location}
              mapStyle={MapStyles.SATELLITE}
              initialZoom={data.type === 'sprint' ? 16 : 15}
            >
              {data.overlayImage ? (
                <OverlayImageLayer
                  corners={data.overlayImage.corners}
                  imageUrl={`/api/v1/event/${data._id}/${data.overlayImage.path}`}
                  interpolateOpacity={true}
                />
              ) : null}
            </CenterMiniMap>
          </Box>

          {data.admin?.personIds?.includes(person._id) ? (
            <CardActions sx={{ flexWrap: 'wrap' }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Button onClick={onEditClick} variant="outlined">
                  Edit
                </Button>
                {!data.published ? (
                  <ConfirmButton onClick={onPublishClick} variant="outlined">
                    Publish
                  </ConfirmButton>
                ) : null}
                <Button onClick={onAddOverlayImageClick} variant="outlined">
                  {!data.overlayImage
                    ? 'Add Map Overlay'
                    : 'Replace Map Overlay'}
                </Button>
                <Button onClick={onControlsClick} variant="outlined">
                  Controls
                </Button>
              </Box>
            </CardActions>
          ) : !data.courses.some((course) =>
              course.trackPersonIds.includes(person._id),
            ) ? (
            <CardActions>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                sx={{ alignItems: 'baseline', flex: 1, justifyContent: 'end' }}
              >
                <Typography>Missing your track?</Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'baseline',
                    flex: 1,
                    justifyContent: 'end',
                  }}
                >
                  <Button href="#/track/upload" variant="outlined">
                    Upload
                  </Button>
                  <Typography>or</Typography>
                  <Button href="#/strava/import" variant="outlined">
                    Import
                  </Button>
                </Stack>
              </Stack>
            </CardActions>
          ) : null}
        </Collapse>
        <Collapse in={mode === 'edit'}>
          <EditDetails id={data._id} data={data} refresh={refresh} />
        </Collapse>
        <Collapse in={mode === 'add-overlay'}>
          <AddOverlay event={data} refresh={refresh} />
        </Collapse>
      </Card>

      {!redact ? (
        <>
          {data?.courses?.length ? (
            <>
              <Box display="flex" flex={1} justifyContent="space-between">
                <Typography variant="h4">Courses</Typography>
              </Box>
              {redactForOthers ? (
                <Alert severity="info">
                  Other users will not be able to see course details until after
                  the "Course Close" time above.
                </Alert>
              ) : null}
              {[...data.courses].sort(sortByCourseTitle).map((course) => (
                <Card key={course._id} sx={{ width: '100%' }}>
                  <CardContent>
                    <Stack spacing={1}>
                      <Box
                        display="flex"
                        flex={1}
                        justifyContent="space-between"
                      >
                        <Typography variant="h3">{course.title}</Typography>
                        <Button
                          href={uri`#/event/${data._id}/course/${course._id}/tracks`}
                          size="small"
                          variant="outlined"
                          sx={{ ml: '1rem', py: 0 }}
                        >
                          Compare Tracks
                        </Button>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={3}
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '100%',
                          overflowY: 'clip',
                          overflowX: 'auto',
                        }}
                      >
                        {!isRogaine(data.type) ? (
                          <>
                            <InfoFieldView label="Controls">
                              {course.controls?.length.toString() ?? '0'}
                            </InfoFieldView>
                            <Divider
                              orientation="vertical"
                              sx={{ height: '2rem' }}
                            />
                          </>
                        ) : null}
                        <InfoFieldNonTextView label="People">
                          {course.trackPersonIds.length ? (
                            <AvatarGroup
                              total={course.trackPersonIds.length}
                              sx={{
                                '.MuiAvatarGroup-avatar': {
                                  fontSize: '10px',
                                  height: '16px',
                                  ml: 0,
                                  width: '16px',
                                },
                              }}
                            >
                              {course.trackPersonIds
                                .slice(0, 10)
                                .map((personId) => (
                                  <PersonAvatar
                                    key={personId}
                                    personId={personId}
                                    size="16px"
                                  />
                                ))}
                            </AvatarGroup>
                          ) : (
                            'None'
                          )}
                        </InfoFieldNonTextView>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </>
          ) : (
            <Alert severity="info">
              Standby for the event organizers to upload the courses for this
              event. Please check back shortly. Thanks.
            </Alert>
          )}
        </>
      ) : (
        <Alert severity="info">
          Courses will be visible when the competition has finished. Please
          check back{' '}
          <RelativeTimestamp
            date={DateTime.fromJSDate(data.close ?? data.finish)}
            past="now"
          />
          .
        </Alert>
      )}
    </Stack>
  </PageTitle>
);
