import { captureException } from '@sentry/react';
import axios from 'axios';
import type { Event, Track } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uri from 'uri-tag';
import { ConnectMatchingTracksView } from './matching-tracks.view.js';
import { withMatchingTracks } from './with-matching-tracks.js';

interface Props {
  event: Event.Type;
}

export const ConnectMatchingTracks: FC<Props> = withMatchingTracks(
  ({ matchingTracks: tracks, ...props }) => {
    const [selectedTrack, setSelectedTrack] = useState<
      Track.Type | undefined
    >();
    const [busy, setBusy] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const onConnectTrackClick = (track: Track.Type): void => {
      setBusy(true);
      axios
        .post(uri`/api/v1/track/${track._id}/set-event`, {
          eventId: props.event._id,
        })
        .then(() => {
          enqueueSnackbar(
            'Track connected to this event. Now please select a course.',
            { variant: 'success' },
          );
          navigate(uri`/track/${track._id}`);
          return;
        })
        .catch((error) => {
          enqueueSnackbar('Unable to connect track to this event.', {
            variant: 'error',
          });
          captureException(error);
          setBusy(false);
        });
    };

    return ConnectMatchingTracksView({
      ...props,
      busy,
      onConnectTrackClick,
      onTrackIdSelected: (id) =>
        setSelectedTrack(tracks.find((track) => track._id === id)),
      selectedTrack,
      tracks,
    });
  },
);
