import { Alert } from '@mui/material';
import type { Event, Track } from 'core';
import uri from 'uri-tag';
import { useAxios } from '../../hooks/use-axios.js';

interface WithMatchingTracks {
  matchingTracks: Track.Type[];
}

interface Props {
  event: Event.Type;
}

export const withMatchingTracks =
  <P extends Props>(
    Component: React.ComponentType<P & WithMatchingTracks>,
  ): React.FC<P> =>
  (props) => {
    const [matchingTracks] = useAxios<{ items: Track.Type[] }>(
      uri`/api/v1/event/${props.event._id}/my-unconnected-tracks`,
    );

    if (matchingTracks.loading) return null;
    if (matchingTracks.error)
      return <Alert severity="error">{matchingTracks.error.message}</Alert>;
    if (!matchingTracks.data?.items.length) return null;

    return <Component {...props} matchingTracks={matchingTracks.data.items} />;
  };
