import { Button, ButtonGroup, Stack, type ButtonProps } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';

export const ConfirmButton: FC<ButtonProps> = (props) => {
  const [confirm, setConfirm] = useState(false);

  if (!confirm) {
    return <Button {...props} onClick={() => setConfirm(true)} />;
  }

  return (
    <Stack direction="row" spacing={0}>
      <Button disabled sx={{ color: 'black' }} variant="text">
        Sure?
      </Button>
      <ButtonGroup>
        <Button
          color="success"
          variant="contained"
          onClick={() => setConfirm(false)}
        >
          No
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={(e) => {
            props.onClick?.(e);
            setConfirm(false);
          }}
        >
          Yes
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
