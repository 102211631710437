import { Container } from '@mui/material';
import type { Event, Person } from 'core';
import type { FC } from 'react';
import { ErrorView } from '../../components/common/error.js';
import { ListPendingView } from '../../components/common/list-pending.js';
import type { UseAxiosResult } from '../../hooks/use-axios.js';
import { NotFoundPage } from '../not-found/page.js';
import { EventDetailsView } from './event-details.js';
import type { ViewMode } from './types.js';

interface Props {
  event: UseAxiosResult<Event.WithCourses | undefined>;
  mode: ViewMode;
  onAddOverlayImageClick: () => void;
  onControlsClick: () => void;
  onEditClick: () => void;
  onPublishClick: () => void;
  person: Person.Type;
  redact: boolean;
  redactForOthers: boolean;
  refresh: () => void;
}

export const EventPageView: FC<Props> = ({
  event: { data, error, loading },
  mode,
  onAddOverlayImageClick,
  onControlsClick,
  onEditClick,
  onPublishClick,
  person,
  redact,
  redactForOthers,
  refresh,
}) => (
  <Container maxWidth="md" sx={{ my: 3 }}>
    {loading ? (
      <ListPendingView />
    ) : error ? (
      <ErrorView error={error} />
    ) : !data ? (
      <NotFoundPage />
    ) : (
      <EventDetailsView
        data={data}
        mode={mode}
        onAddOverlayImageClick={onAddOverlayImageClick}
        onControlsClick={onControlsClick}
        onEditClick={onEditClick}
        onPublishClick={onPublishClick}
        person={person}
        redact={redact}
        redactForOthers={redactForOthers}
        refresh={refresh}
      />
    )}
  </Container>
);
