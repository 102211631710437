import { captureException } from '@sentry/browser';
import axios from 'axios';
import type { Event } from 'core';
import { mustRedact } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { useAxios } from '../../hooks/use-axios.js';
import { useCache } from '../../providers/cache.js';
import type { ViewMode } from './types.js';
import { EventPageView } from './view.js';

const Controller: FC = () => {
  const { person } = useAuthenticated();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { event: cache } = useCache();

  const [mode, setMode] = useState<ViewMode>('view');
  const { id } = useParams();

  const [event, refresh] = useAxios<Event.WithCourses>(
    id && uri`/api/v1/event/${id}`,
  );

  const onPublishClick = (): void => {
    if (!id) return;

    axios
      .post(uri`/api/v1/event/${id}/publish`, {})
      .then(() => {
        enqueueSnackbar('Event published', { variant: 'success' });
        return undefined;
      })
      .finally(() => {
        cache.invalidate(id);
        refresh();
      })
      .catch((err: Error) => {
        captureException(err);
        if (err instanceof Error) {
          enqueueSnackbar(`Unable to update: ${err.message}`, {
            variant: 'error',
          });
        } else {
          throw err;
        }
      });
  };

  return EventPageView({
    event,
    mode,
    onAddOverlayImageClick: () => setMode('add-overlay'),
    onControlsClick: () => {
      id && navigate(uri`/event/${id}/controls`);
    },
    onEditClick: () => setMode('edit'),
    onPublishClick,
    person,
    redact: event.data ? mustRedact(event.data, person._id) : false,
    redactForOthers: event.data ? mustRedact(event.data, undefined) : false,
    refresh: () => {
      setMode('view');
      refresh();
    },
  });
};

export default Controller;
