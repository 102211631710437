import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import type { Track } from 'core';
import type { FC } from 'react';

interface Props {
  busy: boolean;
  selectedTrack: Track.Type | undefined;
  onConnectTrackClick: (track: Track.Type) => void;
  onTrackIdSelected: (id: string) => void;
  tracks: Track.Type[];
}

export const ConnectMatchingTracksView: FC<Props> = ({
  busy,
  onConnectTrackClick,
  onTrackIdSelected,
  selectedTrack,
  tracks,
}) => (
  <Alert severity="info">
    <AlertTitle>Matching Tracks</AlertTitle>
    <Stack spacing={1}>
      <Typography>
        You have tracks matching this event that are not yet connected to any
        event.
      </Typography>
      <FormControl>
        <FormLabel id="select-track-radio-group">
          Please select a track to connect it with this event
        </FormLabel>
        <RadioGroup
          aria-labelledby="select-track-radio-group"
          name="select-track-radio-group"
          value={selectedTrack?._id ?? ''}
          onChange={(evt) => {
            onTrackIdSelected((evt.target as HTMLInputElement).value);
          }}
        >
          {tracks.map((track) => (
            <FormControlLabel
              key={track._id}
              value={track._id}
              control={<Radio />}
              label={track.title}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <LoadingButton
        disabled={!selectedTrack}
        loading={busy}
        onClick={() => selectedTrack && onConnectTrackClick(selectedTrack)}
        variant="outlined"
      >
        Connect
      </LoadingButton>
    </Stack>
  </Alert>
);
