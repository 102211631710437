import { captureException } from '@sentry/browser';
import axios from 'axios';
import type { Event } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import uri from 'uri-tag';
import { useEventState } from '../../event-create/store/event-state.js';
import { View } from './view.js';

interface Props {
  event: Event.WithCourses;
  refresh: () => void;
}

export const AddOverlay: FC<Props> = ({ event, refresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const state = useEventState(event);

  const onSaveClick = useCallback((): void => {
    setSaving(true);
    axios
      .post(
        uri`/api/v1/event/${event._id}/overlay-image`,
        state.overlayImage.tempFile,
      )
      .then(() => {
        enqueueSnackbar('Event updated', { variant: 'success' });
        refresh();
        return undefined;
      })
      .catch((err: Error) => {
        captureException(err);
        enqueueSnackbar(`Unable to update: ${err.message}`, {
          variant: 'error',
        });
        setSaving(false);
      });
  }, [event._id, state.overlayImage.tempFile, enqueueSnackbar, refresh]);

  return View({
    canSave: !!state.overlayImage.tempFile,
    onCancelClick: refresh,
    onSaveClick,
    saving,
    state,
  });
};
