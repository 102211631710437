import { captureException } from '@sentry/react';
import axios from 'axios';
import { Event } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import uri from 'uri-tag';
import { isIssue, isObject } from 'validata';
import { useCache } from '../../../providers/cache.js';
import type { ExistingEntityViewProps } from '../../../utils/types.js';
import { useEventState } from '../../event-create/store/event-state.js';
import { View } from './view.js';

export const EditDetails: FC<ExistingEntityViewProps<Event.Type>> = ({
  id,
  data,
  refresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const { event } = useCache();
  const state = useEventState(data);

  const onSaveClick = useCallback((): void => {
    setSaving(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { published, ...rest } = state.details.all;

    const body = isObject(Event.patchContract).process(rest);
    if (isIssue(body)) {
      console.log(body.issues);
      enqueueSnackbar('Please correct validation issues', { variant: 'error' });
      return;
    }

    axios
      .patch(uri`/api/v1/event/${id}`, body.value)
      .then(() => {
        enqueueSnackbar('Event updated', { variant: 'success' });
        return undefined;
      })
      .finally(() => {
        event.invalidate(id);
        refresh();
        setSaving(false);
      })
      .catch((err: Error) => {
        captureException(err);
        if (err instanceof Error) {
          enqueueSnackbar(`Unable to update: ${err.message}`, {
            variant: 'error',
          });
        } else {
          throw err;
        }
      });
  }, [id, state.details.all, enqueueSnackbar, refresh, event.invalidate]);

  return View({
    canSave: !!state.details.valid,
    onCancelClick: refresh,
    onSaveClick,
    saving,
    state,
  });
};
