import { ArrowBack as CancelIcon, Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CardActions } from '@mui/material';
import type { FC } from 'react';
import { PageCard } from '../../../components/step-page/page-card.js';
import { EventMapOverlayStep } from '../../event-create/steps/event-map-overlay/controller.js';
import type { EventState } from '../../event-create/store/event-state.js';

interface Props {
  canSave: boolean;
  onCancelClick: () => void;
  onSaveClick: () => void;
  saving: boolean;
  state: EventState;
}

export const View: FC<Props> = ({
  canSave,
  onCancelClick,
  onSaveClick,
  saving,
  state,
}) => (
  <PageCard>
    <EventMapOverlayStep state={state} />

    <CardActions>
      <LoadingButton
        color="primary"
        loading={saving}
        onClick={onCancelClick}
        startIcon={<CancelIcon />}
        variant="outlined"
      >
        Cancel
      </LoadingButton>
      <LoadingButton
        color="primary"
        disabled={!canSave}
        loading={saving}
        onClick={onSaveClick}
        startIcon={<SaveIcon />}
        variant="contained"
      >
        Save
      </LoadingButton>
    </CardActions>
  </PageCard>
);
