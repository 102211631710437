import { FacebookRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import type { FC } from 'react';

export const ShareOnFacebook: FC<{ url?: string }> = ({
  url = 'https://otrax.app/#/events',
}) => (
  <Button
    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url,
    )}&amp;src=sdkpreparse`}
    target="_blank"
    startIcon={<FacebookRounded />}
    size="small"
    variant="contained"
    sx={{
      background: '#1877F2',
      '&:hover': { background: '#0b5fcc' },
      height: 'fit-content',
    }}
  >
    Share
  </Button>
);
